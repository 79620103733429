import {mdiTrayArrowDown} from '@mdi/js';
import {Button} from "../Button";
import Icon from '@mdi/react';
import React from 'react';
import classNames from 'classnames';

import * as styles from './DownloadButton.module.scss';

export function DownloadButton({className, ...props}) {
    return <Button className={classNames(styles.root, className)} noHover component="button"
                   appearance="primary" {...props}>
        <Icon className={styles.icon} path={mdiTrayArrowDown} color="white" size={'40px'}/>
        <span className={styles.title}>Download</span>
    </Button>
}
